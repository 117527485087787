import React from "react";
import { Link } from "gatsby";
import "./header-small.scss";
import Icon from "../images/icon.png";

const HeaderSmall = () => {
  const globalNavOpen = () => {
    const globalNav = document.getElementById("global-nav");
    globalNav.classList.toggle("global-nav-open");
  };
  const navChildOpen = (e) => {
    const childId = e.currentTarget.getAttribute("data-list");
    childId.toString();
    const child = document.getElementById(childId);
    child.classList.toggle("child-open");
  };
  return (
    <header className="header-small">
      <div className="container">
        <Link to="/" className="logo">
          <img src={Icon} alt="icon" />
          DLAPP
        </Link>
        <button className="hamburger" onClick={globalNavOpen}>
          <i className="material-icons">menu</i>
        </button>
      </div>
      {/* nav */}
      <nav className="global-nav" id="global-nav">
        <div className="global-nav-list">
          <Link to="/">DLAPPについて</Link>
          <button onClick={navChildOpen} data-list="child-1">
            <i className="material-icons">expand_more</i>
          </button>
        </div>
        <div className="global-nav-list child" id="child-1" data-list="child-1">
          <Link to="/sub">child-1</Link>
          <Link to="/sub">child-1</Link>
          <Link to="/sub">child-1</Link>
          <Link to="/sub">child-1</Link>
        </div>
        <div className="global-nav-list">
          <Link to="/">DLAPPの特徴</Link>
          <button onClick={navChildOpen} data-list="child-2">
            <i className="material-icons">expand_more</i>
          </button>
        </div>
        <div className="global-nav-list child" id="child-2" data-list="child-2">
          <Link to="/sub">child-2</Link>
          <Link to="/sub">child-2</Link>
          <Link to="/sub">child-2</Link>
          <Link to="/sub">child-2</Link>
          <Link to="/sub">child-2</Link>
        </div>
        <div className="global-nav-list">
          <Link to="/">使い方</Link>
        </div>
        <div className="global-nav-list">
          <Link to="/">お問い合わせ</Link>
        </div>
      </nav>
    </header>
  );
};

export default HeaderSmall;

import React from "react";
import Header from "./header";
import HeaderSmall from "./header-small";
import Footer from "./footer";
import "../globals/globals.scss";
import Helmet from "react-helmet";
import Icon from "../images/favicon.ico";

const Layout = (props) => {
  return (
    <>
      <Helmet>
        <html lang="ja" />
        <title>Car</title>
        <link rel="icon" type="image/png" href={Icon} />
      </Helmet>
      <Header />
      <HeaderSmall />
      <main>{props.children}</main>
      <Footer />
    </>
  );
};

export default Layout;

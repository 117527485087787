import React from "react";
import { Link } from "gatsby";
import "./header.scss";
import Icon from "../images/icon.png";

const Header = () => {
  const isOpen = (e) => {
    const getId = e.currentTarget.getAttribute("data-list");
    getId.toString();
    const target = document.getElementById(getId);
    target.classList.add("child-is-open");
  };
  const isClose = (e) => {
    const getId = e.currentTarget.getAttribute("data-list");
    getId.toString();
    const target = document.getElementById(getId);
    target.classList.remove("child-is-open");
  };
  return (
    <header className="header-full">
      <div className="container">
        <Link to="/" className="logo">
          <img src={Icon} alt="icon" />
          DLAPP
        </Link>
        <nav>
          <ul className="parent">
            <li className="parent-list">
              <Link
                to="/"
                className="parent-list-link"
                onMouseEnter={isOpen}
                onMouseLeave={isClose}
                data-list="list-1"
              >
                DLAPPについて
              </Link>
            </li>
            <li className="parent-list">
              <Link
                to="/sub"
                className="parent-list-link"
                onMouseEnter={isOpen}
                onMouseLeave={isClose}
                data-list="list-2"
              >
                DLAPPの特徴
              </Link>
            </li>
            <li className="parent-list">
              <Link to="/sub" className="parent-list-link">
                使い方
              </Link>
            </li>
            <li className="parent-list">
              <Link to="/sub" className="parent-list-link">
                お問い合わせ
              </Link>
            </li>
          </ul>
        </nav>
        <ul className="child" id="list-1">
          <li>
            <Link to="/sub">child-1</Link>
          </li>
          <li>
            <Link to="/sub">child-1</Link>
          </li>
          <li>
            <Link to="/sub">child-1</Link>
          </li>
          <li>
            <Link to="/sub">child-1</Link>
          </li>
        </ul>
        <ul className="child" id="list-2">
          <li>
            <Link to="/sub">child-2</Link>
          </li>
          <li>
            <Link to="/sub">child-2</Link>
          </li>
          <li>
            <Link to="/sub">child-2</Link>
          </li>
          <li>
            <Link to="/sub">child-2</Link>
          </li>
          <li>
            <Link to="/sub">child-2</Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import { Link } from "gatsby";
import "./footer.scss";
import Icon from "../images/icon.png";

const Footer = () => {
  return (
    <footer>
      <section className="footer-top">
        <div className="container">
          <ul className="info">
            <li className="info-logo">
              <img src={Icon} alt="icon" />
              <span>DLAPP</span>
            </li>
            <li className="info-heading">MAIL</li>
            <li>XXXX@XXXX.XX</li>
            <li className="info-heading">TEL/FAX</li>
            <li>XX-XXXX-XXXX</li>
            <li className="info-heading">ADDLESS</li>
            <li>東京都XX区XX XX-XX-X</li>
            <li>
              <Link to="/sub" className="link-btn">
                お問い合わせ
              </Link>
            </li>
          </ul>
          <ul className="map">
            <li data-type="heading">ホーム</li>
            <li>
              <Link to="/sub">DLAPPについて</Link>
            </li>
            <li>
              <Link to="/sub">DLAPPの特徴</Link>
            </li>
            <li>
              <Link to="/sub">ご利用企業様の声</Link>
            </li>
            <li>
              <Link to="/sub">導入までの流れ</Link>
            </li>
          </ul>
          <ul className="map">
            <li data-type="heading">DLAPPの特徴</li>
            <li>
              <Link to="/sub">サンプルテキスト</Link>
            </li>
            <li>
              <Link to="/sub">サンプルテキスト</Link>
            </li>
            <li>
              <Link to="/sub">サンプルテキスト</Link>
            </li>
            <li>
              <Link to="/sub">サンプルテキスト</Link>
            </li>
          </ul>
          <ul className="map">
            <li data-type="heading">使い方</li>
            <li>
              <Link to="/sub">サンプルテキスト</Link>
            </li>
            <li>
              <Link to="/sub">サンプルテキスト</Link>
            </li>
            <li>
              <Link to="/sub">サンプルテキスト</Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="footer-bottom">
        <p>©︎Demand Link Co.,Ltd.</p>
      </section>
    </footer>
  );
};

export default Footer;
